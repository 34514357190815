import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import CustomReactShare from '../components/CustomReactShare'

const Sutra = ({data}) => {
let url = `https://sutras.yoga/book${data.strapiSutra.book.number}sutra${data.strapiSutra.number}/`

  return(
    
<Layout>
  <CustomReactShare title={data.strapiSutra.translation} excerpt={data.strapiSutra.interpretation.slice(0,500)} url={url} />
    <h1>{data.strapiSutra.translation}</h1>
    <p>{data.strapiSutra.interpretation}</p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)
}

export default Sutra


export const query = graphql`
query($id: String!){
  strapiSutra(id:{eq:$id}) {
  
  
      id
  translation
  interpretation
  number
  book {
    number
  }
}}
    
  
`